import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  background: #f2f2f2;
  width: 100%;
  padding: 40px 0;

  @media screen and (max-width: 950px) {
    padding: 80px 0 40px;
  }

  .footer {
    &__holder {
      display: flex;
      align-items: center;
      justify-content: space-between;

      width: 100%;
      max-width: 1760px;
      margin: 0 80px;

      @media screen and (max-width: 950px) {
        flex-direction: column;
        align-items: flex-start;
        margin: 0 40px;
      }

      @media screen and (max-width: 650px) {
        margin: 0 20px;
      }
    }

    &__social-media {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @media screen and (max-width: 950px) {
        margin-bottom: 40px;
      }

      > img {
        width: 240px;
        margin-bottom: 40px;

        @media screen and (max-width: 950px) {
          width: 160px;
        }
      }

      .social-media__group {
        display: flex;

        a + a {
          margin-left: 18px;
        }

        a {
          border-radius: 50%;
          height: 52px;
          width: 52px;

          transition: all 0.2s ease-in-out;

          &:hover {
            background-color: #ffffff;
          }

          @media screen and (max-width: 950px) {
            height: 30px;
            width: 30px;
          }
        }

        img {
          height: 52px;
          width: 52px;

          @media screen and (max-width: 950px) {
            height: 36px;
            width: 36px;
          }
        }
      }
    }

    &__copyright {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;

      font-size: 1rem;

      @media screen and (max-width: 950px) {
        width: 100%;
        align-items: center;
      }

      @media screen and (max-width: 650px) {
        font-size: 0.75rem;
      }

      .copyright__petbrands {
        color: #343648;
        text-align: center;
      }

      .copyright__uxnine {
        text-align: center;

        a {
          color: #343648;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        span {
          color: #ff6171;
        }
      }
    }
  }
`;
