import React from 'react';

import { Helmet } from 'react-helmet';

interface HeadData {
  pageTitle?: string;
}

const Head: React.FC<HeadData> = ({
  pageTitle = 'Participações no segmento Pet',
}) => {
  return (
    <Helmet>
      <html lang="pt-br" />

      <title>PetBrands – {pageTitle}</title>
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,800&display=swap"
        rel="stylesheet"
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta name="theme-color" content="#ffb56c" />
      <meta name="apple-mobile-web-app-status-bar-style" content="#ffb56c" />
      <meta name="msapplication-navbutton-color" content="#ffb56c" />

      <meta property="og:site_name" content="Petbrands" />
      <meta property="og:title" content="Petbrands" />
      <meta property="og:description" content="Participações no segmento pet" />
      <meta property="og:type" content="website" />
      <meta
        property="og:image"
        itemProp="image"
        content="https://petbrands-preview.netlify.app/img/card.png"
      />
      <meta
        property="og:image:secure_url"
        itemProp="image"
        content="https://petbrands-preview.netlify.app/img/card.png"
      />
      <meta property="og:image:width" content="100" />
      <meta property="og:image:height" content="100" />
      <meta property="og:locale:alternate" content="pt_BR" />
      <meta property="og:url" content="http://petbrands.com.br/" />

      <meta name="twitter:title" content="Petbrands" />
      <meta
        name="twitter:description"
        content="Participações no segmento pet"
      />
      <meta name="twitter:url" content="http://petbrands.com.br/" />
      <meta
        name="twitter:image"
        content="https://petbrands-preview.netlify.app/img/card.png"
      />
    </Helmet>
  );
};

export default Head;
