import { createGlobalStyle } from 'styled-components';
import font from '../theme/variables/font';

export default createGlobalStyle`
  * {
    padding: 0;
    margin: 0;

    box-sizing: border-box;
  }

  body {
    position: relative;
    font-family: ${font.family.poppins};
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;
