import styled, { css } from 'styled-components';

interface IHeader {
  navbarStatus: boolean;
  menuStatus: boolean;
}

export const HeaderContainer = styled.header<IHeader>`
  z-index: 80;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ navbarStatus }) =>
    navbarStatus ? 'rgba(255,255,255, 0.8)' : 'transparent'};
  width: 100%;
  height: 100px;

  transition: all 0.5s;

  .header {
    &__holder {
      display: flex;
      justify-content: space-between;
      align-items: center;

      width: 100%;
      max-width: 1760px;
      padding: 0 80px;

      > a {
        z-index: 1;
        display: flex;
      }

      @media screen and (max-width: 950px) {
        padding: 0 40px;
      }

      @media screen and (max-width: 650px) {
        padding: 0 20px;
      }

      ${({ navbarStatus }) =>
        navbarStatus &&
        css`
          nav a {
            color: #343648;
            text-shadow: none;

            @media screen and (max-width: 1400px) {
              color: #ffffff;
            }
          }
        `};
    }

    &__hidden {
      width: 237px;

      @media screen and (max-width: 1400px) {
        display: none;
      }
    }

    &__logo {
      @media screen and (max-width: 650px) {
        width: 155px;
      }
    }

    &__menu-bars {
      z-index: 1;
      display: none;

      background: transparent;
      height: 22px;
      width: 26px;

      border: 0;
      outline: 0;

      cursor: pointer;

      .menu-bars__bar {
        background-color: ${({ menuStatus }) =>
          menuStatus ? '#ffffff' : '#343648'};
        height: 6px;
        width: 100%;
        transition: all 0.4s ease-in-out;

        & + div {
          margin-top: 2px;
        }

        &--top {
          transform: ${({ menuStatus }) =>
            menuStatus ? 'rotate(45deg) translate(4px, 4px)' : 'none'};
        }

        &--bottom {
          transform: ${({ menuStatus }) =>
            menuStatus ? 'rotate(-45deg) translate(7px, -7px)' : 'none'};
        }

        &--center {
          width: ${({ menuStatus }) => (menuStatus ? '0' : '100%')};
          transition: none;
        }
      }

      @media screen and (max-width: 1400px) {
        display: block;
      }
    }
  }

  nav {
    display: flex;
    align-items: center;

    @media screen and (max-width: 1400px) {
      position: fixed;
      top: 0;
      right: ${({ menuStatus }) => (menuStatus ? '0' : '-50%')};
      flex-direction: column;
      align-items: center;
      justify-content: center;

      background-color: rgba(0, 0, 0, 0.7);
      backdrop-filter: blur(12px);
      height: 100%;
      width: 50%;
      transition: all 0.4s ease-in-out;
    }

    @media screen and (max-width: 785px) {
      right: ${({ menuStatus }) => (menuStatus ? '0' : '-100%')};

      width: 100%;
      transition: all 0.4s ease-in-out;
    }
  }
`;
