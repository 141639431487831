import React from 'react';

import Logo from '../../assets/img/mini-logo.svg';
import Linkedin from '../../assets/img/icons/linkedin.svg';
import Facebook from '../../assets/img/icons/facebook.svg';
import Twitter from '../../assets/img/icons/twitter.svg';
import Instagram from '../../assets/img/icons/instagram.svg';

import { Container } from './styles';

const Footer: React.FC = () => {
  return (
    <Container>
      <div className="footer__holder">
        <div className="footer__social-media">
          <img src={Logo} alt="Logotipo Petbrands" />
          <div className="social-media__group">
            <a href="https://www.linkedin.com/">
              <img src={Linkedin} alt="Linkedin Petbrands" />
            </a>
            <a href="https://www.facebook.com/">
              <img src={Facebook} alt="Facebook Petbrands" />
            </a>
            <a href="https://twitter.com/">
              <img src={Twitter} alt="Twitter Petbrands" />
            </a>
            <a href="https://www.instagram.com/">
              <img src={Instagram} alt="Instagram Petbrands" />
            </a>
          </div>
        </div>
        <div className="footer__copyright">
          <div className="copyright__petbrands">
            Petbrands Participações Ltda. – CNPJ/MF 32.560.451/0001-70
          </div>
          <div className="copyright__uxnine">
            <a href="https://uxnine.com/">
              Site desenvolvido por <span>UX</span>nine
            </a>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Footer;
